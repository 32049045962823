import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";
import Button from "@material-ui/core/Button";
import SynchsFeed from "../cockpitComponents/SynchsFeed";
import { Link } from "react-router-dom";
import ShareIcon from "@material-ui/icons/Share";
import ClearIcon from "@material-ui/icons/Clear";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

import "./styles/article.css";
const ReactMarkdown = require("react-markdown/with-html");
class Article extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: [],
      shareOpen: false
    };
  }

  toggleShare = () => {
    //document.getElementById("spotifyIframe").classList.toggle("open");
    if (this.state.shareOpen) {
      this.setState({
        shareOpen: false
      });
    } else {
      this.setState({
        shareOpen: true
      });
    }
  };

  async fetchData() {
    // var locale = this.props.match.params.locale;
    // var filter;
    // if (locale === undefined) {
    //   filter = "title_slug";
    // } else {
    //   filter = `title_${locale}_slug`;
    // }

    const response = await fetch(
      `http://scorpio.badbyte.ch/cms/api/collections/get/synchs`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          //filter: { [filter]: this.props.match.params.id },
          filter: { url_handle: this.props.match.params.id },
          lang: this.props.match.params.locale
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData.entries[0]
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      window.scroll(0, 0);
      await this.setState({
        fetching: true
      });

      await this.fetchData();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    var splittedDate;
    if (!this.state.fetching) {
      splittedDate = this.state.data.date.split("-");
    }

    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "article " + this.state.data.title
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }

    return (
      <div className="article">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "en"
              }
            />
            <meta charSet="utf-8" />
            <title>{this.state.data.title} - Synchs - SCORPIO MUSIC</title>
            <meta
              property="og:title"
              content={`${this.state.data.title} - News - SCORPIO MUSIC`}
            />
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          {!this.state.fetching ? (
            <div className="articleInner">
              <div
                className="topbg"
                style={{
                  backgroundImage: `url(http://scorpio.badbyte.ch/cms/api/cockpit/image?token=189f6f339371195fcccbd85236fdee&src=${
                    this.state.data.image._id
                  }&w=1024&h=1024&q=80&o=true)`,
                  filter: "blur(20px)"
                }}
              />
              <div className="articleContent">
                <h1 style={{ fontSize: "2.6rem" }}>{this.state.data.title}</h1>
                <div className="date">
                  {splittedDate[2]}-{splittedDate[1]}-{splittedDate[0]}
                </div>

                <ReactMarkdown
                  source={this.state.data.content}
                  escapeHtml={false}
                />
                <a
                  href="#"
                  id="share"
                  onClick={this.toggleShare}
                  className="link"
                >
                  <strong>SHARE</strong> <ShareIcon />
                </a>
              </div>
              {this.state.shareOpen ? (
                <div className="shareButtons">
                  {console.log(window.location.href)}
                  <div className="shareButtonsInner">
                    <div
                      style={{
                        textAlign: "left",
                        paddingBottom: "1rem",
                        lineHeight: "1.6rem"
                      }}
                    >
                      <strong>SHARE</strong>
                      <ClearIcon
                        style={{ cursor: "pointer", float: "right" }}
                        onClick={this.toggleShare}
                      />
                    </div>

                    <FacebookShareButton url={window.location.href}>
                      <FacebookIcon size={64} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton url={window.location.href}>
                      <TwitterIcon size={64} round={true} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={window.location.href}>
                      <WhatsappIcon size={64} round={true} />
                    </WhatsappShareButton>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          <SynchsFeed limit={8} locale={this.props.match.params.locale} />

          <div style={{ textAlign: "center", paddingBottom: "4rem" }}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={
                !this.props.match.params.locale
                  ? `/synchs`
                  : `/${this.props.match.params.locale}/synchs`
              }
            >
              SEE ALL
            </Button>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Article;
