import React, { PureComponent } from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";
import Fade from "react-reveal/Fade";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import "./styles/videos.css";
class Videos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: [],
      currentVideo: null
    };
  }

  async fetchData() {
    const response = await fetch(
      `http://scorpio.badbyte.ch/cms/api/collections/get/releases`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          filter: { published: true },
          lang: this.props.match.params.locale,
          sort: { date: -1 },
          fields: { yt: 1, artist: 1, title: 1 }
          // skip: 0,
          // limit: this.state.limit
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData.entries
    });
  }

  playVideo = id => {
    this.setState({
      currentVideo: id
    });
  };

  // async fetchMore() {
  //   const response = await fetch(
  //     `http://scorpio.badbyte.ch/cms/api/collections/get/blog`,
  //     {
  //       method: "post",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
  //       },
  //       body: JSON.stringify({
  //         filter: { published: true },
  //         lang: this.props.match.params.locale,
  //         sort: { date: -1 },
  //         skip: this.state.limit + this.state.skip,
  //         limit: this.state.limit
  //       })
  //     }
  //   );
  //   if (!response.ok) {
  //     return;
  //   }
  //   const jsonData = await response.json();
  //   var newdata = this.state.data.concat(jsonData.entries);
  //   this.setState({
  //     data: newdata,
  //     skip: this.state.skip + this.state.limit
  //   });
  // }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();
    this.setState({
      fetching: false
    });
  }

  render() {
    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "videos"
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    return (
      <div className="videos">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "en"
              }
            />
            <meta charSet="utf-8" />
            <title>Videos - SCORPIO MUSIC</title>
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          {!this.state.fetching ? (
            <div className="videoIframeContainer">
              <iframe
                style={{
                  display: "block"
                }}
                width="100%"
                height="640"
                src={`https://www.youtube.com/embed/${this.state.currentVideo}`}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen={true}
              />
            </div>
          ) : null}

          <div className="videosThumbsContainer">
            {!this.state.fetching
              ? this.state.data.map((item, i) => {
                  return item.yt && item.yt.length > 0
                    ? item.yt.map((video, i) => {
                        if (video && video.value.include) {
                          if (!this.state.currentVideo) {
                            this.setState({
                              currentVideo: video.value.url
                            });
                          }
                          return (
                            <div
                              className="thumb"
                              onClick={() => this.playVideo(video.value.url)}
                            >
                              <img
                                src={`http://img.youtube.com/vi/${
                                  video.value.url
                                }/mqdefault.jpg`}
                              />
                              <strong>{item.title}</strong>
                              <br />
                              {item.artist}
                            </div>
                          );
                        }
                      })
                    : null;
                })
              : null}
          </div>
        </Layout>
      </div>
    );
  }
}

export default Videos;
