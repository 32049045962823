import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";

import CockpitComponentsDispatcher from "../CockpitComponentsDispatcher";

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    const response = await fetch(
      `http://scorpio.badbyte.ch/cms/api/singletons/get/homepage`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          lang: this.props.match.params.locale
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    sessionStorage.setItem(
      `home_${this.props.match.params.id}_${this.props.match.params.locale}`,
      JSON.stringify(jsonData)
    );

    this.setState({
      data: jsonData
    });
  }

  async componentDidMount() {
    // window.fbAsyncInit = function() {
    //   console.log(window.FB.api);
    //   window.FB.init({
    //     appId: "703842256719754",
    //     xfbml: true,
    //     version: "v2.6"
    //   });
    //   window.FB.api("/468016990703752", "GET", {}, function(response) {
    //     console.log(response);
    //   });
    // };
    // (function(d, s, id) {
    //   var js,
    //     fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) {
    //     return;
    //   }
    //   js = d.createElement(s);
    //   js.id = id;
    //   js.src = "//connect.facebook.net/en_US/sdk.js";
    //   fjs.parentNode.insertBefore(js, fjs);
    // })(document, "script", "facebook-jssdk");

    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });
    const cachedHome = sessionStorage.getItem(
      `home_${this.props.match.params.id}_${this.props.match.params.locale}`
    );
    if (cachedHome) {
      this.setState({
        data: JSON.parse(cachedHome),
        fetching: false
      });
    }

    await this.fetchData();

    await this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      window.scroll(0, 0);
      await this.setState({
        fetching: true
      });
      const cachedHome = sessionStorage.getItem(
        `home_${this.props.match.params.id}_${this.props.match.params.locale}`
      );
      if (cachedHome) {
        this.setState({
          data: JSON.parse(cachedHome),
          fetching: false
        });
      }

      await this.fetchData();

      await this.setState({
        fetching: false
      });
    }
  }

  render() {
    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "Homepage"
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }

    return (
      <div className="home">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "en"
              }
            />
            <meta charSet="utf-8" />
            <title>Home - SCORPIO MUSIC</title>
            <meta name="description" content={this.state.data.meta} />
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          {!this.state.fetching ? (
            <CockpitComponentsDispatcher
              data={this.state.data}
              locale={this.props.match.params.locale}
            />
          ) : null}
        </Layout>
      </div>
    );
  }
}

export default Home;
