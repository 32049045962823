import React, { PureComponent } from "react";
import "./styles/footer.css";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import PrivacyPopUp from "../privacyPopUp/PrivacyPopUp";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
const ReactMarkdown = require("react-markdown/with-html");

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true
    };
  }

  async fetchFooter() {
    const response = await fetch(
      `http://scorpio.badbyte.ch/cms/api/singletons/get/footer`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          lang: this.props.locale,
          populate: 6
          // fields: { name: 1, url_handle: 1 }
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      footer: jsonData
    });
  }

  async componentDidMount() {
    this.setState({
      fetching: true
    });
    await this.fetchFooter();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.setState({
        fetching: true
      });

      await this.fetchFooter();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    return (
      <div>
        <div
          className="footer"
          style={
            !this.state.fetching
              ? {
                  backgroundColor: this.state.footer.bg_color,
                  color: this.state.footer.text_color
                }
              : null
          }
        >
          {!this.state.fetching ? (
            <div className="footerInner">
              {this.state.footer.logo ? (
                <>
                  <Link
                    to={!this.props.locale ? `/` : `/${this.props.locale}/`}
                  >
                    <img
                      className="footerLogo"
                      src={`http://scorpio.badbyte.ch/cms/storage/uploads${
                        this.state.footer.logo.path
                      }`}
                      alt="Seven"
                      style={{
                        height: "6rem",
                        display: "block",
                        width: "auto",
                        maxWidth: "100%"
                      }}
                    />
                  </Link>
                  <br />
                  <div
                    className="socialheader"
                    style={{ textAlign: "center", display: "block" }}
                  >
                    <a
                      href="http://www.facebook.com/scorpiodigital"
                      target="_blank"
                      title="Facebook"
                    >
                      <img src="/assets/icons/facebook.svg" alt="Facebook" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCXaPWso3Q85gEzQ-DJ5lBoQ"
                      target="_blank"
                      title="Youtube"
                    >
                      <img
                        src="/assets/icons/youtube_official.svg"
                        alt="Youtube"
                      />
                    </a>
                    <a
                      href="https://twitter.com/scorpio_music"
                      target="_blank"
                      title="Twitter"
                    >
                      <img src="/assets/icons/twitter.svg" alt="Twitter" />
                    </a>
                    <a
                      href="http://www.instagram.com/scorpiomusic"
                      target="_blank"
                      title="Instagram"
                    >
                      <img src="/assets/icons/insta.svg" alt="Instagram" />
                    </a>
                  </div>
                  <br />
                </>
              ) : null}
              <ReactMarkdown
                source={this.state.footer.content}
                escapeHtml={false}
              />

              <div className="footerNav">
                {this.state.footer.nav
                  ? this.state.footer.nav.map((item1, i1) => {
                      return item1.field.name === "parent" ? (
                        <List className="footerList" key={i1}>
                          <ListItem>
                            <ListItemText>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item1.value.label
                                }}
                              />
                            </ListItemText>
                          </ListItem>

                          {item1.value.links.map((item2, i2) => {
                            return item2.field.name === "page" ? (
                              <ListItem
                                button
                                key={i2}
                                component={Link}
                                to={
                                  !this.props.locale
                                    ? `/${item1.value.handle}/${
                                        item2.value.link.url_handle
                                      }`
                                    : `/${this.props.locale}/${
                                        item1.value.handle
                                      }/${item2.value.link.url_handle}`
                                }
                                style={{
                                  color: this.props.textColor
                                }}
                              >
                                <ListItemText>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item2.value.label
                                    }}
                                  />
                                </ListItemText>
                              </ListItem>
                            ) : item2.field.name === "custom" ? (
                              item2.value.url.indexOf("/") === 0 ? (
                                <ListItem
                                  button
                                  key={i2}
                                  component={Link}
                                  to={
                                    !this.props.locale
                                      ? `${item2.value.url}`
                                      : `/${this.props.locale}${
                                          item2.value.url
                                        }`
                                  }
                                  style={{
                                    color: this.props.textColor
                                  }}
                                >
                                  <ListItemText>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item2.value.label
                                      }}
                                    />
                                  </ListItemText>
                                </ListItem>
                              ) : (
                                <ListItem
                                  button
                                  key={i2}
                                  component="a"
                                  target={item2.value.new_tab ? "_blank" : ""}
                                  rel={
                                    item2.value.new_tab
                                      ? "noopener noreferrer"
                                      : ""
                                  }
                                  href={item2.value.url}
                                  style={{
                                    color: this.props.textColor
                                  }}
                                >
                                  <ListItemText>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item2.value.label
                                      }}
                                    />
                                  </ListItemText>
                                </ListItem>
                              )
                            ) : null;
                          })}
                        </List>
                      ) : item1.field.name === "page" ? (
                        <List className="footerList" key={i1}>
                          <ListItem
                            button
                            component={Link}
                            to={
                              !this.props.locale
                                ? `/${item1.value.link.url_handle}`
                                : `/${this.props.locale}/${
                                    item1.value.link.url_handle
                                  }`
                            }
                            style={{
                              color: this.props.textColor
                            }}
                          >
                            <ListItemText>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item1.value.label
                                }}
                              />
                            </ListItemText>
                          </ListItem>
                        </List>
                      ) : item1.field.name === "custom" ? (
                        <List className="footerList" key={i1}>
                          {item1.value.url.indexOf("/") === 0 ? (
                            <ListItem
                              button
                              key={i1}
                              component={Link}
                              to={
                                !this.props.locale
                                  ? `${item1.value.url}`
                                  : `/${this.props.locale}${item1.value.url}`
                              }
                              style={{
                                color: this.props.textColor
                              }}
                            >
                              <ListItemText>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item1.value.label
                                  }}
                                />
                              </ListItemText>
                            </ListItem>
                          ) : (
                            <ListItem
                              button
                              key={i1}
                              component="a"
                              target={item1.value.new_tab ? "_blank" : ""}
                              rel={
                                item1.value.new_tab ? "noopener noreferrer" : ""
                              }
                              href={item1.value.url}
                              style={{
                                color: this.props.textColor
                              }}
                            >
                              <ListItemText>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item1.value.label
                                  }}
                                />
                              </ListItemText>
                            </ListItem>
                          )}
                        </List>
                      ) : null;
                    })
                  : null}
              </div>
            </div>
          ) : null}
        </div>
        <PrivacyPopUp />
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#111",
            padding: "1rem",
            color: "#fff"
          }}
        >
          © SCORPIO MUSIC | <Link to="/privacy-policy">Privacy Policy</Link>
          &nbsp; | powered by&nbsp;
          <a href="https://badbyte.ch" target="_blank">
            BADBYTE
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
