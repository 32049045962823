import React, { PureComponent } from "react";

import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";
import Fade from "react-reveal/Fade";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import "./styles/articles.css";
class Articles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: [],
      limit: 10,
      skip: 0,
      noMore: false,
      searchterm: ""
    };
  }

  async fetchData() {
    const response = await fetch(
      `http://scorpio.badbyte.ch/cms/api/collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          filter: {
            published: true,
            $or: [
              { title: { $regex: this.state.searchterm } },
              { content: { $regex: this.state.searchterm } }
            ]
          },
          lang: this.props.match.params.locale,
          sort: { date: -1 },
          skip: 0,
          limit: this.state.limit
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData.entries,
      noMore:
        jsonData.total <= this.state.skip + this.state.limit ? true : false
    });
    console.log(jsonData.total);
  }

  async fetchMore() {
    const response = await fetch(
      `http://scorpio.badbyte.ch/cms/api/collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "189f6f339371195fcccbd85236fdee"
        },
        body: JSON.stringify({
          filter: {
            published: true,
            $or: [
              { title: { $regex: this.state.searchterm } },
              { content: { $regex: this.state.searchterm } }
            ]
          },
          lang: this.props.match.params.locale,
          sort: { date: -1 },
          skip: this.state.limit + this.state.skip,
          limit: this.state.limit
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    var newdata = this.state.data.concat(jsonData.entries);
    this.setState({
      data: newdata,
      skip: this.state.skip + this.state.limit,
      noMore:
        jsonData.total <= this.state.skip + this.state.limit ? true : false
    });
    console.log(jsonData);
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();
    this.setState({
      fetching: false
    });
  }

  async search() {
    console.log(document.getElementById("search").value);
    this.setState({
      searchterm: document.getElementById("search").value
    });
    await this.setState({
      fetching: true
    });

    await this.fetchData();
    this.setState({
      fetching: false
    });
    console.log(this.state.searchterm);
  }

  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.search();
    }
  };

  render() {
    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "news"
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    return (
      <div className="articles">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "en"
              }
            />
            <meta charSet="utf-8" />
            <title>Releases - SCORPIO MUSIC</title>
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          <div className="articlesFeed">
            <div
              className="articlesFeedContainer"
              style={{
                backgroundColor: this.props.bgColor,
                position: "relative"
              }}
            >
              <div
                className="searchbar"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "2rem"
                }}
              >
                <TextField
                  onKeyPress={this.handleKeyPress}
                  id="search"
                  label="Search in News"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.search()}
                >
                  Search
                </Button>
              </div>
              <Fade bottom cascade>
                <div className="articlesFeedContainerInner">
                  {!this.state.fetching
                    ? this.state.data.map((article, i) => {
                        const splittedDate = article.date.split("-");

                        return (
                          <Card
                            key={i}
                            className={
                              i === 0
                                ? "articleFeedCard first"
                                : "articleFeedCard"
                            }
                          >
                            <Link
                              className="bigLink"
                              title={article.title}
                              to={
                                !this.props.match.params.locale
                                  ? `/news/${article.url_handle}`
                                  : `/${this.props.match.params.locale}/news/${
                                      article.url_handle
                                    }`
                              }
                            >
                              <CardActionArea>
                                {article.image ? (
                                  <CardMedia
                                    image={`http://scorpio.badbyte.ch/cms/api/cockpit/image?token=189f6f339371195fcccbd85236fdee&src=${
                                      article.image._id
                                    }&w=600&h=600&q=80&o=true`}
                                    title={article.image.title}
                                    style={{ height: "160px" }}
                                  />
                                ) : null}
                                <CardContent>
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: "0 0 0.5rem 0",
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    <strong>{article.title}</strong>
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      padding: "0 0 0.5rem 0"
                                    }}
                                  >
                                    <i>
                                      {" "}
                                      {splittedDate[2]}-{splittedDate[1]}-
                                      {splittedDate[0]}
                                    </i>
                                  </p>
                                </CardContent>
                              </CardActionArea>
                            </Link>
                            <CardActions
                              className="articleFeedCardActions"
                              style={{ display: "none" }}
                            >
                              <Button
                                size="small"
                                component={Link}
                                color="primary"
                                variant="contained"
                                to={
                                  !this.props.match.params.locale
                                    ? `/news/${article.url_handle}`
                                    : `/${
                                        this.props.match.params.locale
                                      }/news/${article.url_handle}`
                                }
                              >
                                Learn More
                              </Button>
                            </CardActions>
                          </Card>
                        );
                      })
                    : null}
                </div>
              </Fade>
              {!this.state.noMore ? (
                <Button
                  onClick={() => {
                    this.fetchMore();
                  }}
                  variant="contained"
                  color="primary"
                >
                  More
                </Button>
              ) : null}
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Articles;
